import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react";
import store, { KeyStore } from "../../store/TranslationsListStore";

type NewTranslationCardProps = {
  newKey: KeyStore;
};

function NewTranslationCard({ newKey }: NewTranslationCardProps) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
        mb: 2,
      }}
      key={newKey.id}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={5.5}>
            <Box sx={{ display: "inline-flex", width: "100%" }}>
              <Checkbox
                checked={newKey.isSelected}
                onChange={() => {
                  newKey.isSelected = !newKey.isSelected;
                }}
                sx={{ mr: 1 }}
              />
              <TextField
                fullWidth
                variant="standard"
                label={t("translations.key")}
                value={newKey.keyId}
                onChange={(e) => {
                  newKey.setKeyId(e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Grid>
          <Grid item xs={5.5}>
            <TextField
              fullWidth
              multiline
              variant="standard"
              label={t("translations.value")}
              value={newKey.draft}
              onChange={(e) => {
                newKey.setDraft(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={0.5} sx={{ ml: -0.5, mr: 0.5, textAlign: "center" }}>
            <Button
              variant="contained"
              disabled={!newKey.isDirty || !newKey.keyId || !newKey.draft}
              sx={{
                // borderRadius: "25px",
                minWidth: "35px",
                maxWidth: "35px",
                maxHeight: "35px",
                width: "35px",
                height: "35px",
                mt: 1,
              }}
              onClick={() => {
                store.handleDraftSave([newKey]);
              }}
            >
              <SaveIcon />
            </Button>
          </Grid>
          <Grid
            item
            xs={0.5}
            sx={{
              ml: -0.5,
              mr: 0.5,
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{
                p: 0,
                minWidth: "35px",
                maxWidth: "35px",
                maxHeight: "35px",
                width: "35px",
                height: "35px",
                mt: 1,
              }}
              onClick={() => store.handleNewKeysRemove([newKey.id])}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default observer(NewTranslationCard);
