import { observer } from "mobx-react";
import NewTranslationCard from "../NewTranslationCard/NewTranslationCard";
import useTranslationListStore from "../../store/TranslationsListStore";

function NewTranslationCardList() {
  const { newTranslationKeys } = useTranslationListStore;

  return (
    <>
      {newTranslationKeys.map((newKey) => (
        <NewTranslationCard key={newKey.id} newKey={newKey} />
      ))}
    </>
  );
}

export default observer(NewTranslationCardList);
