import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IosShareIcon from "@mui/icons-material/IosShare";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import DraggableList from "../../../components/Draggable/DraggableList";
import {
  Language,
  ProjectLanguage,
  ProjectLanguageList,
} from "../../../models/Language";
import { Project } from "../../../models/Project";
import { get, put } from "../../../services/Api/ApiFunctions";
import languagesList from "../../../utils/constants";
import { compare, reorder } from "../../../utils/helpers";
import LanguagesManagementDialog from "../LanguagesManagementDialog/LanguagesManagementDialog";
import "./LanguagesList.css";

export default function LanguagesList() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  const navigate = useNavigate();
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null
  );
  const openExport = Boolean(anchorElExport);
  const handleClickExport = (event: MouseEvent<HTMLElement>) => {
    setAnchorElExport(event.currentTarget);
  };
  const handleCloseExport = () => {
    setAnchorElExport(null);
  };
  const [project, setProject] = useState<Project>();
  const [projectLanguages, setProjectLanguages] = useState<
    ProjectLanguageList[]
  >([]);
  const [countries, setCountries] = useState<Language[]>([]);
  const [update, setUpdate] = useState(true);
  const languages = languagesList.sort(compare);
  const [openLanguagesManagementDialog, setOpenLanguagesManagementDialog] =
    useState<boolean>(false);
  const [json, setJSON] = useState(true);
  const [offlineJson, setOfflineJSON] = useState(true);
  const [csv, setCSV] = useState(true);
  const [xml, setXML] = useState(true);
  const [strings, setStrings] = useState(true);
  const [xliff, setXLIFF] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);
  const [toDeleteValue, setToDeleteValue] = useState<{
    code: string;
    label?: string;
  }>();

  useEffect(() => {
    if (projectLanguages) {
      const languagesArray: Language[] = [];
      for (let i = 0; i < projectLanguages.length; i += 1) {
        for (let j = 0; j < projectLanguages[i].countries.length; j += 1) {
          languagesArray.push({
            value: projectLanguages.map((x) => x.countries.map((y) => y.code))[
              i
            ][j],
            title: projectLanguages.map((x) => x.countries.map((y) => y.label))[
              i
            ][j],
          });
        }
      }
      setCountries(languagesArray);
    }
  }, [projectLanguages]);

  function download(filename, data) {
    const element = document.createElement("a");
    element.setAttribute("href", `data:application/zip;base64,${data}`);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const handleTranslationsExport = () => {
    const format: string[] = [];
    if (json) {
      format.push("JSON");
    }
    if (offlineJson) {
      format.push("OFFLINE_JSON");
    }
    if (csv) {
      format.push("CSV");
    }
    if (xml) {
      format.push("XML");
    }
    if (strings) {
      format.push("STRINGS");
    }
    if (xliff) {
      format.push("XLIFF");
    }
    get(
      `/projects/${id}/translations/export`,
      new URLSearchParams(`format=${format.join(",")}`)
    ).then((res) => {
      download("export.zip", res.data);
    });
  };

  const removeLanguages = (value: { code: string; label?: string }) => {
    const languagesArray = projectLanguages.map(({ language, ...keepAttrs }) =>
      keepAttrs.countries.map(
        ({ draftCount, publishedCount, ...otherAttrs }) => otherAttrs
      )
    );
    const languagesBodyObj: { languages: { code: string; label?: string }[] } =
      {
        languages: [],
      };
    for (let i = 0; i < languagesArray.length; i += 1) {
      for (let j = 0; j < languagesArray[i].length; j += 1) {
        languagesBodyObj.languages.push(languagesArray[i][j]);
      }
    }
    const elementToDelete = languagesBodyObj.languages.findIndex(
      (x) => x.code === value.code
    );
    if (elementToDelete !== -1) {
      languagesBodyObj.languages.splice(elementToDelete, 1);
    }
    put<{ languages: ProjectLanguage[] }>(
      `/projects/${id}/languages`,
      languagesBodyObj
    )
      .then(() => {
        toast.success(t("languages.language-edit-successful"));
        setUpdate(true);
      })
      .catch(() => {
        toast.error(t("languages.language-edit-error"));
      });
  };

  const editLanguages = (
    value: { code: string; label?: string },
    type: "closed" | "add" | "remove"
  ) => {
    if (type === "remove") {
      setOpenConfirmationDialog(true);
      setToDeleteValue(value);
    } else {
      const languagesArray = projectLanguages.map(
        ({ language, ...keepAttrs }) =>
          keepAttrs.countries.map(
            ({ draftCount, publishedCount, ...otherAttrs }) => otherAttrs
          )
      );
      const languagesBodyObj: {
        languages: { code: string; label?: string }[];
      } = {
        languages: [],
      };
      for (let i = 0; i < languagesArray.length; i += 1) {
        for (let j = 0; j < languagesArray[i].length; j += 1) {
          languagesBodyObj.languages.push(languagesArray[i][j]);
        }
      }
      if (type === "add") {
        const addLangIndex = languagesBodyObj.languages.findLastIndex(
          (x) => x.code.split("-")[0] === value.code.split("-")[0]
        );
        if (addLangIndex !== -1) {
          languagesBodyObj.languages.splice(addLangIndex + 1, 0, {
            code: value.code,
            label: value.label,
          });
        } else {
          languagesBodyObj.languages.push({
            code: value.code,
            label: value.label,
          });
        }
      }
      put<{ languages: ProjectLanguage[] }>(
        `/projects/${id}/languages`,
        languagesBodyObj
      )
        .then(() => {
          toast.success(t("languages.language-edit-successful"));
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("languages.language-edit-error"));
        });
    }
  };

  useEffect(() => {
    if (id && update) {
      window.scroll({ top: 0, behavior: "smooth" });
      get<Project>(`/projects/${id}`)
        .then((res) => {
          const languageArray: {
            language: string;
            countries: ProjectLanguage[];
          }[] = [];
          for (let i = 0; i < res.data.languages.length; i += 1) {
            const langIndex = languageArray.findIndex(
              (x) => x.language === res.data.languages[i].code.split("-")[0]
            );
            if (langIndex === -1) {
              languageArray.push({
                language: res.data.languages[i].code.split("-")[0],
                countries: [res.data.languages[i]],
              });
            } else {
              languageArray[langIndex].countries.push(res.data.languages[i]);
            }
          }
          setProjectLanguages(languageArray);
          setProject(res.data);
          setUpdate(false);
        })
        .catch(() => {
          setUpdate(false);
          toast.error(t("errors.generic-error"));
        });
    }
  }, [id, update]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (destination && source.index !== destination.index) {
      const destinationDroppableIndex = parseFloat(
        destination.droppableId.split("-")[2]
      );
      const sourceDroppableIndex = parseFloat(source.droppableId.split("-")[2]);

      // dropped outside the list
      if (!destination || destinationDroppableIndex !== sourceDroppableIndex)
        return;

      if (destinationDroppableIndex === 0 && destination.index === 0) {
        toast.error(t("languages.error-base-language"));
        return;
      }

      const newProjectLanguagesCountries = reorder(
        projectLanguages[destinationDroppableIndex].countries,
        source.index,
        destination.index
      );

      setProjectLanguages((prevState) => {
        const newState = prevState.map((obj, i) => {
          if (i === destinationDroppableIndex) {
            return { ...obj, countries: newProjectLanguagesCountries };
          }
          return obj;
        });
        const languagesArray = newState.map(({ language, ...keepAttrs }) =>
          keepAttrs.countries.map(
            ({ draftCount, publishedCount, ...otherAttrs }) => otherAttrs
          )
        );
        const languagesBodyObj: {
          languages: { code: string; label?: string }[];
        } = {
          languages: [],
        };
        for (let i = 0; i < languagesArray.length; i += 1) {
          for (let j = 0; j < languagesArray[i].length; j += 1) {
            languagesBodyObj.languages.push(languagesArray[i][j]);
          }
        }
        // provare a mettere await
        put<{ languages: ProjectLanguage[] }>(
          `/projects/${id}/languages`,
          languagesBodyObj
        )
          .then(() => {
            toast.success(t("languages.language-edit-successful"));
          })
          .catch(() => {
            toast.error(t("languages.language-edit-error"));
          });
        return newState;
      });
    }
  };

  const onDragStart = () => {
    // good times
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const handleClose = async (
    type: "closed" | "add" | "remove",
    returnValue?: { code: string; label?: string }
  ) => {
    setOpenLanguagesManagementDialog(false);
    if (type === "add" && returnValue) {
      editLanguages(returnValue, type);
    } else if (type === "remove" && returnValue) {
      editLanguages(returnValue, type);
    }
  };

  const handleConfirmationDialogClose = async (returnValue: boolean) => {
    setOpenConfirmationDialog(false);
    if (returnValue === true && toDeleteValue) {
      removeLanguages(toDeleteValue);
    }
  };

  return (
    <Box sx={{ width: "95%", margin: "6rem auto" }} className="layoutMinHeight">
      <LanguagesManagementDialog
        projectLanguages={countries}
        open={openLanguagesManagementDialog}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        title={t("projects.remove-dialog-title")}
        text={t("projects.remove-dialog-text")}
      />
      <Grid container sx={{ mb: 2 }}>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignSelf: "center",
            mb: isMobile ? 2 : 0,
            display: "inline-flex",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "25px",
              backgroundColor: "white",
              minWidth: "40px",
              width: "40px",
              height: "40px",
              mr: 1,
            }}
            onClick={() => navigate("/projects")}
          >
            <ChevronLeftIcon />
          </Button>
          <Typography variant="h5" sx={{ mt: 0.75 }}>
            {project ? project.name : t("languages.project")} -&gt;{" "}
            {t("languages.languages-list")}
          </Typography>
        </Grid>
        {isMobile ? null : <Grid item md={3} />}
        <Grid item md={5} xs={12}>
          <Grid
            container
            sx={{
              textAlign: isMobile ? "auto" : "right",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={7} xs={12} sx={{ mb: isMobile ? 2 : 0 }}>
              <Button
                variant="contained"
                sx={{ borderRadius: "25px", color: "secondary.main" }}
                onClick={() => navigate(`/projects/${id}/settings`)}
              >
                {t("languages.project-settings")}
              </Button>
            </Grid>
            <Grid item md={5} xs={12}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "25px",
                  color: "secondary.main",
                }}
                onClick={() => setOpenLanguagesManagementDialog(true)}
              >
                {t("languages.languages-management")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 5 }}>
        <Grid item md={1} xs={12}>
          <Button
            variant="contained"
            sx={{ color: "secondary.main" }}
            onClick={handleClickExport}
            id="export-button"
            aria-controls={openExport ? "export-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openExport ? "true" : undefined}
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
          >
            {t("translations.export")}
          </Button>
          <Menu
            id="export-menu"
            MenuListProps={{
              "aria-labelledby": "export-button",
            }}
            anchorEl={anchorElExport}
            open={openExport}
            onClose={handleCloseExport}
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={json} onClick={() => setJSON(!json)} />
                  }
                  label="JSON"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={offlineJson}
                      onClick={() => setOfflineJSON(!offlineJson)}
                    />
                  }
                  label="OFFLINE JSON"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={csv} onClick={() => setCSV(!csv)} />
                  }
                  label="CSV"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={xml} onClick={() => setXML(!xml)} />
                  }
                  label="XML"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={strings}
                      onClick={() => setStrings(!strings)}
                    />
                  }
                  label="STRINGS"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={xliff}
                      onClick={() => setXLIFF(!xliff)}
                    />
                  }
                  label="XLIFF"
                />
              </FormGroup>
            </MenuItem>
            <MenuItem
              disabled={
                !json && !offlineJson && !csv && !xml && !strings && !xliff
              }
              disableRipple
              onClick={() => handleTranslationsExport()}
            >
              <IosShareIcon sx={{ ml: "-0.2rem", mr: 1 }} />
              {t("translations.export")}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      {projectLanguages &&
        projectLanguages.map((language, i) => (
          <Accordion
            className="accordion-border-radius"
            sx={{
              boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
              mb: 2,
            }}
            defaultExpanded
            key={language.language}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid
                container
                justifyContent="space-between"
                sx={{ alignItems: "center" }}
              >
                <Grid item xs={3}>
                  <Typography>
                    {language.language}{" "}
                    {languages.find((x) => x.value === language.language)
                      ? `(${
                          languages.find((x) => x.value === language.language)
                            ?.title
                        })`
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right", mr: 3 }}>
                  <Grid container sx={{ justifyContent: "flex-end" }}>
                    <Grid item sx={{ mr: 3 }}>
                      {language.countries[0].draftCount} {t("languages.draft")}
                    </Grid>
                    <Grid item>
                      {language.countries[0].publishedCount}{" "}
                      {t("languages.published")}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <DraggableList
                baseLanguage={i === 0}
                index={i}
                items={language.countries}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                handleDelete={editLanguages}
              />
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
}
