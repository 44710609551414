import { Project } from "../../../models/Project";
import { Translation } from "../../../models/Translation";
import { get, post, put } from "../../../services/Api/ApiFunctions";

const TranslationListRestClient = {
  getProjectAsync: async (projectId: string): Promise<Project> => {
    const response = await get<Project>(`/projects/${projectId}`);
    return response.data;
  },

  getBaseLanguageAsync: async (projectId: string): Promise<Translation> => {
    const response = await get<Translation>(
      `/projects/${projectId}/translations/base-language`
    );
    return response.data;
  },

  getTranslationAsync: async (
    projectId: string,
    languageCode: string
  ): Promise<Translation> => {
    const response = await get<Translation>(
      `/projects/${projectId}/translations/${languageCode}`
    );
    return response.data;
  },

  updateDrafts: async (
    projectId: string,
    currentLang: string,
    body: {
      keys: {
        value: string | null;
        keyId: string;
        status: string;
      }[];
      version: number;
    }
  ) => {
    const response = await put(
      `/projects/${projectId}/translations/${currentLang}/drafts`,
      body
    );

    return response.data;
  },
  publishDrafts: async (
    projectId: string,
    currentLang: string,
    body: {
      keys: {
        value: string | null;
        keyId: string;
        status: string;
      }[];
      version: number;
    }
  ) => {
    const response = await put(
      `/projects/${projectId}/translations/${currentLang}/published`,
      body
    );

    return response.data;
  },

  deleteKeys: async (
    projectId: string,
    currentLang: string,
    body: {
      keys: {
        value: null;
        keyId: string;
        status: string;
      }[];
      version: number;
    }
  ) => {
    const response = await put(
      `/projects/${projectId}/translations/${currentLang}/published`,
      body
    );

    return response.data;
  },

  importTranslation: async (
    projectId: string,
    currentLang: string,
    formData: FormData
  ) => {
    const response = await post(
      `/projects/${projectId}/translations/import?langCode=${currentLang}`,
      formData
    );

    return response.data;
  },
};

export default TranslationListRestClient;
