import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Footer.css";

function Footer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const curretYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        width: "inherit",
        height: "150px",
        pt: 2,
        overflow: "hidden",
        px: 2,
        pb: 3,
        backgroundColor: "#0068C1",
        color: "secondary.main",
      }}
    >
      <Container>
        <Grid container>
          {!isMobile && (
            <Grid item xs={12} md={4} mb={{ xs: 2, md: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ justifyContent: "center" }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 64,
                    width: 72,
                  }}
                  alt={t("alt.logo")}
                  // eslint-disable-next-line global-require
                  src={require("../../../assets/images/logo-white.png")}
                />
                <Typography
                  sx={{ fontSize: { xs: 34, md: 40 }, ml: 2, color: "#fff" }}
                  fontFamily="LamaRounded"
                >
                  trAPP
                </Typography>
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} md={4} mb={{ xs: 4, md: 0 }}>
            <Stack>
              {!isMobile && (
                <Grid container sx={{ justifyContent: "center" }}>
                  <Grid
                    item
                    spacing={0}
                    width="100%"
                    md={6}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid item xs={2} sm={1} md={4} lg={4}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/var_group/"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.ig-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/ig.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={1} md={4} lg={4}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/vargroup-spa/"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.li-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/li.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} sm={1} md={4} lg={4}>
                      <a
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/VarGoup"
                        target="_BLANK"
                      >
                        <Box
                          component="img"
                          alt={t("alt.fb-icon")}
                          // eslint-disable-next-line global-require
                          src={require("../../../assets/images/fb.png")}
                          width={{ xs: 32, md: 45 }}
                          height={{ xs: 32, md: 45 }}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item sx={{ mt: 2, justifyItems: "center" }}>
                <Typography color="secondary.main" fontSize={14}>
                  &copy; {curretYear} - Var Group SPA -{" "}
                  {t("footer.all-rights-reserved")}
                </Typography>
              </Grid>
            </Stack>
          </Grid>
          {/* <Grid item xs={12} my={{ xs: 4, md: 8 }}></Grid> */}
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            mb={{ xs: 4, md: 0 }}
            mt={{ xs: -2, md: 0 }}
          >
            <Grid container>
              <Grid item xs={6} md={12} sx={{ justifyItems: "center" }}>
                <Stack>
                  <Typography
                    color="secondary.main"
                    component="a"
                    target="_blank"
                    href="https://www.vargroup.com/Contact"
                    sx={{ pb: 1, width: "100%", textDecoration: "underline" }}
                  >
                    {t("footer.contacts")}
                  </Typography>
                  <Typography
                    color="secondary.main"
                    component="a"
                    target="_blank"
                    href="https://www.vargroup.com/Privacy-Policy"
                    sx={{
                      pb: 1,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.privacy")}
                  </Typography>

                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      color="secondary.main"
                      component="a"
                      target="_blank"
                      href="https://sitecore.vargroup.it/-/media/Project/Var Group Tenant/Var Group Corporate Website/Condizioni generali e SLA/Condizioni Generali di Vendita"
                      sx={{
                        pb: 1,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {t("footer.terms")}
                    </Typography>
                  </Stack>
                  {isMobile && (
                    <Typography color="secondary.main" fontSize={10}>
                      &copy; {curretYear} - Var Group SPA -{" "}
                      {t("footer.all-rights-reserved")}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
