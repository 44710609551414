import RenderIfVisible from "react-render-if-visible";
import { observer } from "mobx-react";
import TranslationCard from "../TranslationCard/TranslationCard";
import ScrollToTopBtn from "../../../../components/ScrollToTopBtn/ScrollToTopBtn";
import useTranslationListStore from "../../store/TranslationsListStore";

function TranslationCardList() {
  const { displayTranslationKeys } = useTranslationListStore;

  return (
    <>
      <ScrollToTopBtn />
      <div>
        {displayTranslationKeys.map((item) => (
          <RenderIfVisible key={item.id}>
            <TranslationCard tranKey={item} />
          </RenderIfVisible>
        ))}
      </div>
    </>
  );
}
export default observer(TranslationCardList);
