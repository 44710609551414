import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { TranslationKey } from "../../../models/Translation";

const createTranslationKeyStore = (
  key?: TranslationKey,
  baseValue?: string
) => {
  // * Do NOT return directly the object wrapped by makeAutoObservable and substitute 'store.' with 'this.'
  // * or the methods will lose the context
  const store = {
    // id will be used for a correct UI management
    id: uuidv4(),
    originalDraft: key?.draft ?? undefined,
    isSelected: false,
    draft: key?.draft ?? undefined,
    keyId: key?.keyId ?? "",
    published: key?.published ?? undefined,
    baseValue,
    isNew: false,
    isDraftEditable: false,
    isPublishedEditable:
      key?.published ?? undefined
        ? true
        : (key?.draft ?? undefined) === undefined,

    setOriginalDraft(value: string | undefined) {
      store.originalDraft = value;
    },
    setIsNew(value: boolean) {
      store.isNew = value;
    },

    setIsSelected(value: boolean) {
      store.isSelected = value;
    },

    setDraft(value?: string) {
      store.draft = value;
    },

    setKeyId(value: string) {
      store.keyId = value;
    },

    setPublished(value?: string) {
      store.published = value;
    },

    setIsDraftEditable(value: boolean) {
      store.isDraftEditable = value;
    },

    setIsPublishedEditable(value: boolean) {
      store.isPublishedEditable = value;
    },

    get isDirty() {
      if (store.isDraftEditable) return true;
      return store.draft !== store.originalDraft;
    },
  };
  return makeAutoObservable(store);
};

// ? Should be transient, instantiating one new for each key
export default createTranslationKeyStore;
