import { Box, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react";
import { useEffect } from "react";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../services/LoadingSpinner/LoadingSpinner";
import "./TranslationsList.css";
import NewTranslationCardList from "./components/NewTranslationCardList/NewTranslationCardList";
import TranslationsListHeader from "./components/TranslationsListHeader/TranslationsListHeader";
import TranslationCardList from "./components/TranslationCardList/TranslationCardList";
import noElementsIllustration from "../../assets/animations/no-elements-animation.json";
import useTranslationListStore from "./store/TranslationsListStore";

function TranslationsList() {
  const {
    isLoading,
    isFiltering,
    newTranslationKeys,
    currentTranslationKeys,
    addNewTranslationKey,
    initStore,
    setIsShiftOn,
  } = useTranslationListStore;
  const { t } = useTranslation();
  const { id, lang } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setIsShiftOn(true);
      }
    };

    document.addEventListener("keydown", eventHandler);
    return () => document.removeEventListener("keydown", eventHandler);
  }, []);

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setIsShiftOn(false);
      }
    };
    document.addEventListener("keyup", eventHandler);
    return () => document.removeEventListener("keyup", eventHandler);
  }, []);

  useEffect(() => {
    if (!isLoading && id && lang) {
      initStore(id, lang);
    }
  }, [id, lang]);

  return (
    <>
      <TranslationsListHeader />
      <Box
        sx={{ width: "95%", margin: "14rem auto" }}
        className="layoutMinHeight"
      >
        {(isFiltering || isLoading) && <LoadingSpinner />}

        <NewTranslationCardList />
        <TranslationCardList />
        {!isLoading &&
          !newTranslationKeys.length &&
          !currentTranslationKeys.length && (
            <Box sx={{ textAlign: "center" }}>
              <Lottie
                animationData={noElementsIllustration}
                style={{
                  width: "256px",
                  margin: "0 auto",
                }}
              />
              <Typography variant="h5">
                {t("translations.no-translations")}
              </Typography>
              <Typography variant="h5">
                {t("translations.add-translation")}
              </Typography>
              <Fab
                color="primary"
                aria-label="add"
                sx={{ mt: 2 }}
                onClick={addNewTranslationKey}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
      </Box>
    </>
  );
}

export default observer(TranslationsList);
