import { TranslationKey } from "../../../models/Translation";
import { KeyStore } from "../store/TranslationsListStore";

export const sortKeys = (keys: KeyStore[]) =>
  keys.slice().sort((a, b) => {
    if (!a.published && b.published) return -1;
    if (a.published && !b.published) return 1;

    const aHasBoth = !!a.published && !!a.draft;
    const bHasBoth = !!b.published && !!b.draft;
    if (aHasBoth && !bHasBoth) return -1;
    if (!aHasBoth && bHasBoth) return 1;

    return a.keyId.localeCompare(b.keyId);
  });

/**
 * Given a keyId and the list of base language keys, it checks if it is present between the base language keys, in that case returns the appropriate base value
 * @param keyId
 * @baseKeys
 * @returns if base value is found it returns the published if present, otherwise the draft. If nothing is found return an empty string
 */
export const getBaseLanguageKeyValue = (
  keyId: string,
  baseKeys: Record<string, TranslationKey>
) => {
  if (!Object.keys(baseKeys).length || !baseKeys[keyId]) return "";

  const key = baseKeys[keyId] as TranslationKey;

  return key.published ?? key.draft ?? "";
};
