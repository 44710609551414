/* eslint-disable no-plusplus */
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  ListItemAvatar,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react";
import Flag from "react-world-flags";
import translationListStore, {
  KeyStore,
} from "../../store/TranslationsListStore";

type TranslationCardProps = {
  tranKey: KeyStore;
};

function TranslationCard({ tranKey }: TranslationCardProps) {
  const {
    isBaseLanguage,
    currentLang,
    handleDraftSave,
    baseLanguageCode,
    isShiftOn,
    displayTranslationKeys,
  } = translationListStore;

  const handleCardSelection = () => {
    if (!isShiftOn) {
      tranKey.setIsSelected(!tranKey.isSelected);
    } else {
      const index = displayTranslationKeys.findIndex(
        (key) => key.id === tranKey.id
      );

      let lastSelected = 0;

      for (let i = index - 1; i >= 0; --i) {
        if (displayTranslationKeys[i].isSelected) {
          lastSelected = i;
          break;
        }
      }

      for (let i = lastSelected; i <= index; i++) {
        displayTranslationKeys[i].setIsSelected(true);
      }
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
        mb: 2,
      }}
      key={tranKey.id}
    >
      <CardContent>
        <Grid container sx={{ placeItems: "center" }}>
          <Grid item xs={6} sx={{ display: "grid" }}>
            <Box sx={{ display: "inline-flex" }}>
              <Checkbox
                checked={tranKey.isSelected}
                onChange={handleCardSelection}
                sx={{ mr: 1 }}
              />
              <Typography sx={{ mt: 1 }}>{tranKey.keyId}</Typography>
            </Box>
            {!isBaseLanguage && tranKey.baseValue && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemAvatar
                  title={baseLanguageCode ?? ""}
                  style={{
                    marginLeft: "0.5em",
                    marginRight: "1em",
                    minWidth: "fit-content",
                  }}
                >
                  <Flag
                    code={
                      // eslint-disable-next-line no-nested-ternary
                      baseLanguageCode
                        ? baseLanguageCode.split("-")[1] === "**"
                          ? baseLanguageCode.split("-")[0]
                          : baseLanguageCode.split("-")[
                              baseLanguageCode.split("-").length - 1
                            ]
                        : ""
                    }
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                    fallback={
                      <Typography sx={{ mb: 0.5, mr: 2 }}>
                        {baseLanguageCode}
                      </Typography>
                    }
                  />
                </ListItemAvatar>

                <TextField
                  size="small"
                  title={tranKey.baseValue}
                  disabled
                  variant="filled"
                  label={t("translations.base-language-value")}
                  value={tranKey.baseValue}
                  sx={{ mb: 1, width: "60%", minHeight: "48px" }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <ListItemAvatar title={currentLang}>
              <Flag
                code={
                  currentLang.split("-")[1] === "**"
                    ? currentLang.split("-")[0]
                    : currentLang.split("-")[currentLang.split("-").length - 1]
                }
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
                fallback={<Typography>{currentLang}</Typography>}
              />
            </ListItemAvatar>
          </Grid>
          <Grid item xs={5}>
            {tranKey.draft !== undefined ? (
              <Box sx={{ display: "flex" }}>
                <TextField
                  size="small"
                  fullWidth
                  multiline
                  className="value-field"
                  variant="filled"
                  label={t("translations.draft-value")}
                  value={tranKey.draft}
                  onChange={(e) => tranKey.setDraft(e.target.value)}
                  sx={{ mb: 1, zIndex: 0 }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        disabled={
                          !tranKey.isDirty ||
                          tranKey.draft === tranKey.published
                        }
                        variant="contained"
                        className="value-field-button"
                        sx={{ height: "100%", minHeight: "48px" }}
                        onClick={() => handleDraftSave([tranKey], false)}
                      >
                        <SaveIcon />
                      </Button>
                    ),
                  }}
                />
                <CircleIcon
                  sx={{ ml: 2, alignSelf: "center" }}
                  style={{
                    color:
                      tranKey.draft !== tranKey.published
                        ? "#FFC100"
                        : "#455A64",
                  }}
                />
              </Box>
            ) : null}
            <Box sx={{ display: "flex" }}>
              <TextField
                fullWidth
                disabled
                size="small"
                className="value-field"
                variant="filled"
                label={t("translations.published-value")}
                value={tranKey.published ?? ""}
                sx={{ zIndex: 0 }}
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      className="value-field-button"
                      sx={{ height: "100%" }}
                      disabled={
                        !tranKey.published &&
                        !tranKey.isPublishedEditable &&
                        tranKey.originalDraft !== undefined
                      }
                      onClick={() => {
                        if (!tranKey.isPublishedEditable) {
                          tranKey.setDraft(tranKey.originalDraft);
                          tranKey.setIsDraftEditable(false);
                          tranKey.setIsPublishedEditable(true);
                        } else {
                          tranKey.setDraft(tranKey.published ?? "");
                          tranKey.setIsDraftEditable(true);
                          tranKey.setIsPublishedEditable(false);
                        }
                      }}
                    >
                      {!tranKey.isPublishedEditable &&
                      tranKey.isDraftEditable ? (
                        <UndoIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </Button>
                  ),
                }}
              />
              <CircleIcon
                sx={{ alignSelf: "center", ml: 2 }}
                style={{ color: "#71C02B" }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default observer(TranslationCard);
