import i18next from "i18next";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import { TranslationKey } from "../../../models/Translation";
import TranslationListRestClient from "../restClient/TranslationListRestClient";
import {
  getBaseLanguageKeyValue,
  sortKeys,
} from "../utilities/translations-list-utils";
import createTranslationKeyStore from "./TranslationKeyStore";
import { isNullOrUndefined } from "../../../utils/helpers";

export type KeyStore = ReturnType<typeof createTranslationKeyStore>;

const createTranslationListStore = () => {
  // * Do NOT return directly the object wrapped by makeAutoObservable and substitute 'store.' with 'this.'
  // * or the methods will lose the context
  const store = {
    projectId: "",
    currentLang: "",
    currentTranslationKeys: <KeyStore[]>[],
    isBaseLanguage: false,
    isLoading: false,
    newTranslationKeys: <KeyStore[]>[],
    searchVal: "",
    projectName: "",
    currentVersion: 0,
    languageLabel: "",
    isFiltering: false,
    projectBaseLanguageKeys: <Record<string, TranslationKey>>{},
    baseLanguageCode: <string | null>null,
    isShiftOn: false,

    setIsFiltering(value: boolean) {
      store.isFiltering = value;
    },
    setIsShiftOn(value: boolean) {
      store.isShiftOn = value;
    },
    setLanguageLabel(value: string) {
      store.languageLabel = value;
    },
    setProjectName(value: string) {
      store.projectName = value;
    },
    setCurrentTranslationKeys(value: KeyStore[]) {
      store.currentTranslationKeys = sortKeys(value);
    },
    setBaseLanguageCode(value: string) {
      store.baseLanguageCode = value;
    },
    setSearchVal(value: string) {
      store.setIsFiltering(true);
      if (store.selectedCount) {
        store.selectedKeys.forEach((key) => key.setIsSelected(false));
      }
      store.searchVal = value;
      window.scrollTo(0, 0);
      store.setIsFiltering(false);
    },
    setIsLoading(value: boolean) {
      store.isLoading = value;
    },
    setCurrentVersion(value: number) {
      store.currentVersion = value;
    },
    setProjectBaseLanguageKeys(keys: TranslationKey[]) {
      keys.forEach((key) => {
        if (!store.projectBaseLanguageKeys[key.keyId]) {
          store.projectBaseLanguageKeys[key.keyId] = key;
        }
      });
    },

    /**
     * Initializes TranslationListModel providing baseLanguage data if the provided is not base
     * @param projectId
     * @param currentLang
     */
    async initStore(projId: string, currentLang: string) {
      if (!projId || !currentLang) return;
      store.projectId = projId;
      store.currentLang = currentLang;

      store.setIsLoading(true);

      try {
        const project = await TranslationListRestClient.getProjectAsync(projId);
        store.setProjectName(project.name);

        // Check if translation is base language or not
        store.isBaseLanguage = currentLang === project?.languages[0].code;

        const projectLanguage = project.languages.find(
          (x) => x.code === currentLang
        );

        if (projectLanguage?.label) {
          store.setLanguageLabel(projectLanguage.label);
        } else if (projectLanguage?.code) {
          store.setLanguageLabel(projectLanguage.code);
        } else {
          store.setLanguageLabel(i18next.t("languages.language"));
        }
      } catch {
        toast.error(i18next.t("errors.generic-error"));
        store.setIsLoading(false);
      }

      // If it's not base lang, fetch base lang translation
      if (!store.isBaseLanguage) {
        try {
          const response = await TranslationListRestClient.getBaseLanguageAsync(
            projId
          );
          store.setBaseLanguageCode(response.languageCode);
          store.setProjectBaseLanguageKeys(response.keys);
        } catch (e) {
          toast.error(i18next.t("errors.generic-error"));
          store.setIsLoading(false);
        }
      }

      try {
        await store.fetchAndManageCurrentTranslationKeys();
      } catch {
        toast.error(i18next.t("errors.generic-error"));
      } finally {
        store.setIsLoading(false);
      }
    },

    /**
     * Fetch current language translation, update the version and if required updates the whole keys list
     * @param setKeys default true, if set to false it will not refresh the whole list but only the version, preventing the lost of unsaved edits (optimistic UI)
     */
    async fetchAndManageCurrentTranslationKeys(setKeys = true) {
      if (!store.projectId || !store.currentLang) return;
      try {
        store.setIsLoading(true);
        const translation = await TranslationListRestClient.getTranslationAsync(
          store.projectId,
          store.currentLang
        );

        store.setCurrentVersion(translation.version);

        if (setKeys) {
          store.setCurrentTranslationKeys(
            translation.keys.map((key) => {
              if (!store.isBaseLanguage) {
                const baseValue = getBaseLanguageKeyValue(
                  key.keyId,
                  store.projectBaseLanguageKeys
                );
                return createTranslationKeyStore(key, baseValue);
              }
              return createTranslationKeyStore(key);
            })
          );
        }
      } catch {
        toast.error(i18next.t("errors.generic-error"));
      } finally {
        store.setIsLoading(false);
      }
    },
    addNewTranslationKey() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      const newKey = createTranslationKeyStore();
      newKey.setIsNew(true);
      store.newTranslationKeys.push(newKey);
    },
    handleSelectAll(value: boolean) {
      store.displayTranslationKeys.forEach((tr) => {
        tr.isSelected = value;
      });
      store.newTranslationKeys.forEach((tr) => {
        tr.isSelected = value;
      });
    },

    handleBulkDraftSave() {
      const filteredSelectedNew = store.selectedNewKeys.filter((x) => x.keyId);
      const filteredSelected = store.selectedKeys.filter(
        (x) => !isNullOrUndefined(x.draft)
      );

      store.handleDraftSave([...filteredSelectedNew, ...filteredSelected]);
    },

    handleNewKeysRemove(idList: string[]) {
      idList.forEach((id) => {
        const index = store.newTranslationKeys.findIndex(
          (key) => key.id === id || key.keyId === id
        );
        if (index !== -1) store.newTranslationKeys.splice(index, 1);
      });
    },

    /**
     * Handles drafts saving, updating the value and preventing the creation of already existing keys
     * @param keys
     * @param fetchData default true. If false the UI will be updated optimistically, preventing the whole list update (with the lost of other editings)
     */
    async handleDraftSave(keys: KeyStore[], fetchData = true) {
      if (keys.length) {
        const filteredArray: KeyStore[] = [];

        // If the key isNew and the key already exists, show a toastr and don't include it in the saving list
        keys.forEach((key) => {
          const exists = !!store.currentTranslationKeys.find(
            (x) => x.keyId === key.keyId
          );
          if (key.isNew && exists) {
            toast.warning(
              i18next.t("errors.existing-key").replace("{0}", key.keyId)
            );
          } else {
            filteredArray.push(key);
          }
        });

        if (filteredArray.length) {
          const payload = filteredArray.map(({ draft, keyId }) => ({
            value: draft?.trim() ?? null,
            keyId,
            status: "in",
          }));

          try {
            store.setIsLoading(true);
            await TranslationListRestClient.updateDrafts(
              store.projectId,
              store.currentLang,
              {
                keys: payload,
                version: store.currentVersion,
              }
            );

            store.handleNewKeysRemove(filteredArray.map((key) => key.id));

            await store.fetchAndManageCurrentTranslationKeys(fetchData);

            // if required, update the keys values in the optimistic way, preventing a list reload
            // * if the saving will fail, execution will end in catch scope before arriving here
            if (!fetchData) {
              filteredArray.forEach((key) => {
                key.setIsDraftEditable(false);
                key.setOriginalDraft(key.draft);
              });
            }
            toast.success(i18next.t("translations.draft-saved"));
          } catch {
            toast.error(i18next.t("errors.generic-error"));
          } finally {
            store.setIsLoading(false);
          }
        }
      }
    },

    async handleBulkPublish() {
      const filteredSelected = store.selectedKeys.filter(
        (x) => !isNullOrUndefined(x.draft)
      );
      if (filteredSelected.length) {
        const keysArray = filteredSelected.map(({ draft, keyId }) => ({
          value: draft?.trim() ?? null,
          keyId,
          status: "in",
        }));

        try {
          store.setIsLoading(true);

          await TranslationListRestClient.publishDrafts(
            store.projectId,
            store.currentLang,
            {
              keys: keysArray,
              version: store.currentVersion,
            }
          );

          toast.success(i18next.t("translations.published"));
          await store.fetchAndManageCurrentTranslationKeys();
        } catch {
          toast.error(i18next.t("errors.generic-error"));
        } finally {
          store.setIsLoading(false);
        }
      }
    },

    async handleBulkKeysDelete() {
      const filteredSelected = store.selectedKeys.filter(
        (x) => !isNullOrUndefined(x.draft) || !isNullOrUndefined(x.published)
      );
      if (filteredSelected.length) {
        const keysArray = filteredSelected.map(({ keyId }) => ({
          value: null,
          keyId,
          status: "out",
        }));
        try {
          store.setIsLoading(true);

          await TranslationListRestClient.deleteKeys(
            store.projectId,
            store.currentLang,
            {
              keys: keysArray,
              version: store.currentVersion,
            }
          );

          await store.fetchAndManageCurrentTranslationKeys();
          toast.success(i18next.t("translations.key-deleted"));
        } catch {
          toast.error(i18next.t("errors.generic-error"));
        } finally {
          store.setIsLoading(false);
        }
      }
    },

    async handleBulkDraftsDelete() {
      const filteredSelected = store.selectedKeys.filter(
        (x) => !isNullOrUndefined(x.draft)
      );

      if (filteredSelected.length) {
        const keysArray = filteredSelected.map(({ keyId }) => ({
          value: null,
          keyId,
          status: "in",
        }));
        try {
          store.setIsLoading(true);

          await TranslationListRestClient.updateDrafts(
            store.projectId,
            store.currentLang,
            {
              keys: keysArray,
              version: store.currentVersion,
            }
          );

          toast.success(i18next.t("translations.draft-cleared"));
          await store.fetchAndManageCurrentTranslationKeys();
        } catch {
          toast.error(i18next.t("errors.generic-error"));
        } finally {
          store.setIsLoading(false);
        }
      }
    },

    async handleTranslationsImport(event: ChangeEvent<HTMLInputElement>) {
      event.preventDefault();

      const { files } = event.target;

      if (files && files.length) {
        const formData = new FormData();
        formData.append("file", files[0]);

        try {
          store.setIsLoading(true);

          await TranslationListRestClient.importTranslation(
            store.projectId,
            store.currentLang,
            formData
          );

          toast.success(i18next.t("translations.imported-successfully"));
          store.fetchAndManageCurrentTranslationKeys();
        } catch {
          toast.error(i18next.t("translations.imported-error"));
        } finally {
          store.setIsLoading(false);
        }
      }
    },

    // ===== COMPUTED =====

    get displayTranslationKeys() {
      if (!store.currentTranslationKeys) return [];

      return store.currentTranslationKeys.filter(
        (key) =>
          key.keyId.toLowerCase().includes(store.searchVal.toLowerCase()) ||
          key.published
            ?.toLowerCase()
            .includes(store.searchVal.toLowerCase()) ||
          key.draft?.toLowerCase().includes(store.searchVal.toLowerCase())
      ) as KeyStore[];
    },

    get isAllSelected() {
      return (
        (!!store.displayTranslationKeys.length ||
          !!store.newTranslationKeys.length) &&
        store.displayTranslationKeys.every((tr) => tr.isSelected === true) &&
        store.newTranslationKeys.every((tr) => tr.isSelected === true)
      );
    },

    get isIndeterminate() {
      return (
        !!store.selectedKeys.length &&
        store.selectedKeys.length !==
          store.displayTranslationKeys.length + store.newTranslationKeys.length
      );
    },

    get selectedKeys() {
      return store.currentTranslationKeys.filter((key) => key.isSelected);
    },

    get selectedNewKeys() {
      return store.newTranslationKeys.filter((key) => key.isSelected);
    },

    get selectedCount() {
      return store.selectedKeys.length + store.selectedNewKeys.length;
    },
  };
  return makeAutoObservable(store);
};

const translationListStore = createTranslationListStore();

// ? it should be a singleton, one only instance to be used by all components
export default translationListStore;
