import { ExpandLess } from "@mui/icons-material";
import { Button } from "@mui/material";
import { memo, useEffect, useState } from "react";

function ScrollToTopBtn() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollY > 0 ? (
    <div
      style={{
        position: "fixed",
        bottom: 50,
        right: 50,
        zIndex: 1000,
      }}
    >
      <Button
        variant="contained"
        sx={{ height: "100%", minHeight: "38px" }}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <ExpandLess />
      </Button>
    </div>
  ) : null;
}

export default memo(ScrollToTopBtn);
