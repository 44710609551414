import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useTranslationListStore from "../../store/TranslationsListStore";

function TranslationsListHeader() {
  const { t } = useTranslation();

  // === UI STATE === START
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(
    null
  );
  const openImport = Boolean(anchorElImport);
  // === UI STATE === END

  const {
    searchVal,
    projectName,
    projectId,
    languageLabel,
    isLoading,
    isAllSelected,
    currentTranslationKeys,
    isIndeterminate,
    selectedCount,
    setSearchVal,
    handleSelectAll,
    handleBulkPublish,
    handleBulkDraftSave,
    handleBulkDraftsDelete,
    handleBulkKeysDelete,
    handleTranslationsImport,
    addNewTranslationKey,
    displayTranslationKeys,
  } = useTranslationListStore;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickImport = (event: MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };
  const handleCloseImport = () => {
    setAnchorElImport(null);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 0px 25px 0px rgba(45, 69, 95, 0.1)",
        width: "100%",
        pt: "5rem",
        zIndex: 1000,
      }}
    >
      <Grid container sx={{ mb: 2, pl: 2, pr: 5 }}>
        <Grid
          item
          md={9.5}
          lg={9.5}
          xs={12}
          sx={{
            alignSelf: "center",
            mb: isMobile ? 2 : 0,
            display: "inline-flex",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: "25px",
              backgroundColor: "white",
              minWidth: "40px",
              width: "40px",
              height: "40px",
              mr: 1,
            }}
            onClick={() =>
              projectId ? navigate(`/projects/${projectId}`) : null
            }
          >
            <ChevronLeftIcon />
          </Button>
          <Typography variant="h5" sx={{ mt: 0.75 }}>
            {projectName || t("languages.project")} -&gt;{" "}
            {t("languages.languages-list")} -&gt; {languageLabel}
          </Typography>
        </Grid>
        <Grid item md={2.5} lg={2.5} xs={12}>
          <TextField
            sx={{ width: "100%" }}
            id="outlined-search"
            label={t("languages.search")}
            type="search"
            value={searchVal}
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mb: 2,
          px: 5,
        }}
      >
        {/* SELECT ALL */}
        <Grid item lg={1.5} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                id="select-all-checkbox"
                checked={!isLoading && isAllSelected}
                disabled={isLoading || !currentTranslationKeys.length}
                onChange={(e) => handleSelectAll(e.target.checked)}
                indeterminate={isIndeterminate}
                inputProps={{ "aria-label": "select all keys" }}
              />
            }
            label={
              <label
                htmlFor="select-all-checkbox"
                style={{ fontWeight: "500" }}
              >
                {t("translations.select-all")}
                <div style={{ justifySelf: "center" }}>
                  {selectedCount} /{" "}
                  <strong>{displayTranslationKeys.length}</strong>
                </div>
              </label>
            }
          />
        </Grid>

        {/* PUBLISH */}
        <Grid item lg={1.8} md={1.8} sx={{ px: 1, textAlign: "center" }}>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              color="success"
              sx={{ color: "secondary.main", width: "100%" }}
              id="publish-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              onClick={handleBulkPublish}
              disableElevation
              endIcon={<PublishIcon />}
            >
              {t("translations.publish")}
            </Button>
          )}
        </Grid>

        {/* SAVE DRAFTS */}
        <Grid item lg={1.8} md={1.8} sx={{ px: 1, textAlign: "center" }}>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              sx={{ color: "secondary.main", width: "100%" }}
              id="save-drafts-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              disableElevation
              onClick={() => {
                handleBulkDraftSave();
                handleClose();
              }}
              endIcon={<SaveIcon />}
            >
              {t("translations.save-drafts")}
            </Button>
          )}
        </Grid>

        {/* CLEAR DRAFTS */}
        <Grid item lg={1.8} md={1.8} sx={{ px: 1, textAlign: "center" }}>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              color="warning"
              sx={{ width: "100%" }}
              id="clear-drafts-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              disableElevation
              onClick={() => {
                handleBulkDraftsDelete();
                handleClose();
              }}
              endIcon={<ClearAllIcon />}
            >
              {t("translations.clear-drafts")}
            </Button>
          )}
        </Grid>
        {/* DELETE */}
        <Grid item lg={1.8} md={1.8} sx={{ px: 1, textAlign: "center" }}>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              color="error"
              sx={{ color: "secondary.main", width: "100%" }}
              id="save-drafts-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              disableElevation
              onClick={() => {
                handleBulkKeysDelete();
                handleClose();
              }}
              endIcon={<DeleteIcon />}
            >
              {t("translations.delete")}
            </Button>
          )}
        </Grid>
        <Grid item lg={1.4} md={1.4} sx={{ px: 1, ml: "auto" }}>
          <Button
            variant="contained"
            sx={{ color: "secondary.main", width: "100%" }}
            onClick={handleClickImport}
            id="import-button"
            aria-controls={openImport ? "import-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openImport ? "true" : undefined}
            disableElevation
            endIcon={<KeyboardArrowDownIcon />}
          >
            {t("translations.import")}
          </Button>
          <Menu
            id="import-menu"
            MenuListProps={{
              "aria-labelledby": "import-button",
            }}
            anchorEl={anchorElImport}
            open={openImport}
            onClose={handleCloseImport}
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <label htmlFor="json-upload-file">
              <Input
                inputProps={{ accept: "application/json" }}
                id="json-upload-file"
                type="file"
                onChange={(e) =>
                  handleTranslationsImport(e as ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />{" "}
              <MenuItem disableRipple>JSON</MenuItem>
            </label>
            <label htmlFor="csv-upload-file">
              <Input
                inputProps={{ accept: "text/csv" }}
                id="csv-upload-file"
                type="file"
                onChange={(e) =>
                  handleTranslationsImport(e as ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />{" "}
              <MenuItem disableRipple>CSV</MenuItem>
            </label>
            <label htmlFor="xml-upload-file">
              <Input
                inputProps={{ accept: "application/xml" }}
                id="xml-upload-file"
                type="file"
                onChange={(e) =>
                  handleTranslationsImport(e as ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />{" "}
              <MenuItem disableRipple>XML</MenuItem>
            </label>
            <label htmlFor="strings-upload-file">
              <Input
                inputProps={{ accept: "text/strings" }}
                id="strings-upload-file"
                type="file"
                onChange={(e) =>
                  handleTranslationsImport(e as ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />{" "}
              <MenuItem disableRipple>STRINGS</MenuItem>
            </label>
            <label htmlFor="xliff-upload-file">
              <Input
                inputProps={{ accept: " application/xliff+xml" }}
                id="xliff-upload-file"
                type="file"
                onChange={(e) =>
                  handleTranslationsImport(e as ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />{" "}
              <MenuItem disableRipple>XLIFF</MenuItem>
            </label>
          </Menu>
        </Grid>
        {/* ADD */}
        <Grid item lg={0.5} md={0.5}>
          <Button
            color="primary"
            sx={{ color: "secondary.main", width: "100%" }}
            aria-label="add"
            onClick={addNewTranslationKey}
            variant="contained"
            id="add-button"
            disableElevation
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(TranslationsListHeader);
