const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const mapRoles = (role, t) => {
  switch (role) {
    case "sa": {
      return "Super Admin";
    }
    case "su": {
      return t("settings.user");
    }
    case "ad": {
      return "Admin";
    }
    case "tr": {
      return t("settings.translator");
    }
    default: {
      return "N/D";
    }
  }
};

const splitLastOccurrence = (str, substring) => {
  const lastIndex = str.lastIndexOf(substring);

  // const before = str.slice(0, lastIndex);

  const after = str.slice(lastIndex + 1);

  return after;
};

function compare(a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

const sha256 = async (str) =>
  crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));

const generateNonce = async () => {
  const hash = await sha256(
    crypto.getRandomValues(new Uint32Array(4)).toString()
  );
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const hashArray = Array.from(new Uint8Array(hash));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

const base64URLEncode = (string) =>
  btoa(
    String.fromCharCode.apply(null, Array.from<number>(new Uint8Array(string)))
  )
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

const isTokenExpired = (token) =>
  Date.now() >= JSON.parse(atob(token.split(".")[1])).exp * 1000;

const isNullOrUndefined = (value) => value === null || value === undefined;

export {
  base64URLEncode,
  compare,
  generateNonce,
  isNullOrUndefined,
  isTokenExpired,
  mapRoles,
  reorder,
  sha256,
  splitLastOccurrence,
};
